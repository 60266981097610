import { useReportError } from '/machinery/ReportError'
import { useLocation } from '@kaliber/routing'

import { ArticlePageDefault, ArticlePageSnackables, ArticlePageSingleSnackable, ArticlePageGedicht, ArticlePageKaliberDefault } from './article-types/ArticlePage'
import { specialURLGeneratorProviderData, URLGeneratorProvider } from '/machinery/URLGenerator'
import { SearchContextProvider } from '/machinery/SearchContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

export default function SpecialArticlePage({ doc, feedData, issues, specials }) {
  const reportError = useReportError()
  const location = useLocation()
  const urlGeneratorData = specialURLGeneratorProviderData()

  const PageType = getPageToRender({ template: doc.metadata.template, reportError, location })

  return (
    <URLGeneratorProvider {...urlGeneratorData}>
      <SearchContextProvider {...{ doc, feedData }}>
        <QueryClientProvider client={queryClient}>
          <PageType {...{ doc, feedData, issues, specials }} />
        </QueryClientProvider>
      </SearchContextProvider>
    </URLGeneratorProvider>
  )
}

function getPageToRender({ template, location, reportError }) {
  switch (template) {
    case 'gedicht':
      return ArticlePageGedicht
    case 'snackables':
      return ArticlePageDefault
    case 'single_snackable':
    case 'single_snackable_content':
      return ArticlePageSingleSnackable
    case 'kaliber-snackables':
      return ArticlePageSnackables
    case 'kaliber_research':
      return ArticlePageKaliberDefault
    case 'kaliber_article':
      return ArticlePageKaliberDefault
    case 'research':
      return ArticlePageDefault
    case 'kunstcollectie':
      return ArticlePageDefault
    case 'default':
    default:
      if (template !== 'default') reportError(new Error(`Could not find template '${template}'. Error occurred on ${location.pathname}`))
      return ArticlePageDefault
  }
}
